import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { useQuery } from '@apollo/client'
import {
  OM_INVESTOR_EMAIL,
  OM_PHONE_NUMBER,
  OM_PHONE_URL,
  TrackedButton,
} from '@flock/shared-ui'
import { Typography } from '@mui/material'
import { InvestorGetV3OneTimeLinkDocument } from '@flock/flock-gql-server/src/__generated__/graphql'

import PersonalInformationPage from '../../components/ExchangeComponents/OrderV3PageComponents/PersonalInformationComponents/PersonalInformationPage'
import LoadingCardPage from '../../components/SharedComponents/LoadingCardPage'
import ResponseReceivedPage from '../../components/SharedComponents/ResponseReceivedPage'

const SharedPersonalInformationV3 = (props: { oneTimeLink: string }) => {
  const { oneTimeLink } = props
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [orderUuid, setOrderUuid] = useState('')
  const [taskUuid, setTaskUuid] = useState('')
  const [legalEntityUuid, setLegalEntityUuid] = useState('')

  const { refetch } = useQuery(InvestorGetV3OneTimeLinkDocument, {
    variables: {
      getV3OneTimeLinkInput: { link: oneTimeLink },
    },
    skip: oneTimeLink === 'completed',
  })

  const fetchQuestionnaireProps = useCallback(async () => {
    if (oneTimeLink === 'completed') {
      setLoading(false)
      return
    }
    try {
      const result = await refetch()
      const { data: questionnaireProps, error: questionnairePropsError } =
        result
      if (questionnairePropsError) {
        setError(true)
      }
      setOrderUuid(questionnaireProps?.getV3OneTimeLink?.orderUuid as string)
      setTaskUuid(questionnaireProps?.getV3OneTimeLink?.taskUuid as string)
      setLegalEntityUuid(
        questionnaireProps?.getV3OneTimeLink?.legalEntityUuid as string
      )
    } catch (e) {
      setError(true)
    }
    setLoading(false)
  }, [refetch, oneTimeLink])

  useEffect(() => {
    fetchQuestionnaireProps()
  }, [fetchQuestionnaireProps])

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        gap="16px"
        sx={{
          backgroundColor: 'gray1.main',
        }}
      >
        <Typography variant="h4">Oops, your link is invalid.</Typography>
        <Typography variant="p2">
          Please refresh the page and try again or contact us for assistance.
        </Typography>

        <Box display="flex" gap="32px">
          <TrackedButton
            size="small"
            onClick={() => {
              window.open(OM_PHONE_URL)
            }}
          >
            Call {OM_PHONE_NUMBER}
          </TrackedButton>
          <TrackedButton
            size="small"
            onClick={() => window.open(`mailto:${OM_INVESTOR_EMAIL}`)}
          >
            Email {OM_INVESTOR_EMAIL}
          </TrackedButton>
        </Box>
      </Box>
    )
  }
  if (loading) {
    return <LoadingCardPage text="Loading questionnaire..." />
  }

  if (oneTimeLink === 'completed') {
    return <ResponseReceivedPage />
  }

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(235, 241, 241, 1)',
        minHeight: '100vh',
      }}
    >
      <PersonalInformationPage
        unauthed
        orderUuid={orderUuid}
        taskUuid={taskUuid}
        legalEntityUuid={legalEntityUuid}
        oneTimeLink={oneTimeLink}
      />
    </Box>
  )
}

export default SharedPersonalInformationV3
